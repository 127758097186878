@import "~bootstrap-less/bootstrap/bootstrap";
@import "../plugins/adminLTE/less/AdminLTE";
@import "../plugins/adminLTE/less/skins/skin-blue.less";
@import "../plugins/iCheck/square/blue.css";
@import "~bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css";
@import "~bootstrap-editable/css/bootstrap-editable.css";
@import "../plugins/daterangepicker/daterangepicker.css";
@import "../plugins/cropper/cropper.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "../plugins/jQueryDatetimepicker/jquery.datetimepicker.css";
@import "~bootstrap-select/dist/css/bootstrap-select.css";

@font-main-color: #333333;

.gallery {
  position:relative;
  input {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    background-color: transparent;
    color: transparent;
  }
}
#modal_crop_image .modal-content{
  width: 1200px;
  img{
    width: 1170px;
  }
}

#sortable_information, #sortable_extra {
  list-style-type: none;
  li {
    border: 2px solid darkblue;
    background: lightgrey;
    box-shadow: 5px 5px 2px #888888;
    padding-top: 20px;
    padding-left: 10px;
    margin-bottom: 10px;
    margin-left: -40px;
  }
}

.category_group{
  padding: 30px;
}
.category_item{
  border: 2px solid darkblue;
  background: lightgrey;
  box-shadow: 5px 5px 2px #888888;
  padding-top: 20px;
  padding-left: 10px;
  margin-bottom: 10px;
  height: 75px;
}

#sortable_information_vehicle, #sortable_extra_vehicle {
  list-style-type: none;
}
#sortable_vehicle {
  list-style-type: none;
  li {
    border: 2px solid darkblue;
    box-shadow: 5px 5px 2px #888888;
    background: lightgrey;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: -40px;
  }
}
.reservation-item{
    border: 3px solid black;
    border-radius: 5px;
    .box-header .row{
        margin-bottom: 20px;
    }
}

.reservation-status{
    border: 1px solid black;
    background-color:  darkslategrey;
    color:white;
    text-align: center;
    margin-bottom: 10px;
}

.reservation-all{
  background-color: #b1dfbb;
}
.reservation-status-created{
  background-color: #f0eeac;
}
.reservation-status-confirmed{
  background-color: #70e89c;
}
.reservation-status-payment-error{
  background-color: #FD6571;
}
.reservation-status-paid-30{
  background-color: #94d1da;
}
.reservation-status-paid-100{
  background-color: #1ab7ea;
}
.reservation-status-collected{
  background-color: #55aaca;
}
.reservation-status-closed{
  background-color: #f2aa7f;
}
.reservation-status-postponed{
  background-color: #f2a3ec;
}

.voucher-percentage{
    background-color: #0d6aad;
    color: white;
}
.voucher-discount{
    background-color: #0c5460;
    color: white;
}
.voucher-referral{
    background-color: #856404;
    color: white;
}
.voucher-collaboration{
    background-color: #721c24;
    color: white;
}
.voucher-inactive{
    background-color: #5e5e5e;
    color: white;
}

.contract-button{
    background-color: #0c5460;
    margin-left: 10px;
    color: white;
}
#reservation-filter button{
  color: black;
  &:hover{
  color:white
}
}

.dropdown-menu  .menu.widget-reservation{
  li{
    border-bottom: 1px solid grey;
    position: relative;
    padding: 10px;
    small{
      color: #999999;
      font-size: 10px;
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}
.reservations-notes{
  button {
    width: 100px;
    margin-top: 20px;
  }
}
.form-control{
  color: @font-main-color;
}

.daylist_btn{
    &.js_load_daylist_modal:hover {
        cursor: pointer ;
        background-color: #117a8b !important;
    }
    &.full{
        background-color: green;
    }
    &.partial {
        background-color: orange;
    }
    &.fully_free{
        background-color: white;
    }
    &.partially_free{
        background-color: grey;
    }
    &.overbooked{
        background-color: red;
    }
}

.contract_update_ok{
    display:none;
}

.contract_update_error{
    display:none;
}

#contract_locked{
    color: red;
}

.contract_save_message{

}
.row_actions {
  display:flex;

  .action{
    cursor: pointer ;
  }
}
.btn-app {
    min-width:52px;
    height: 40px;
    padding:8px 5px;
    .glyphicon{
        font-size: 12px;
    }
}

#modal_file{
  z-index: 20000;
}

#modal-contract{
  z-index: 10000;
}

.navbar-custom-menu{
  font-size: 20px;
  .label-big{
    font-size: 14px !important;
    right: 0px !important;
  }
}

.alert_active{
  color: red;
  font-weight: bold;
  animation: animate 1.5s linear infinite;
  text-align: center;
}

.alert_inactive{
  text-align: center;
}

@keyframes animate{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 0.7;
  }
  100%{
    opacity: 0;
  }
}
